<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
import NumberInputSpinner from "vue-number-input-spinner";
import { convertDateTime, convertPrice } from "@/_helpers/index";
import Skeleton from "@/components/skeleton.vue";
import imgDummy from "@/assets/images/splash_logo.png";

/**
 * Starter page
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    NumberInputSpinner,
    Skeleton,
  },
  page: {
    title: "Stok",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Starter Page",
      items: [
        {
          text: "Stok",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      imgDummy: imgDummy,
      isLoading: false,
      isLoadingModal: false,
      isLoadingModalDetail: false,
      apiUrl: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        cloth: process.env.VUE_APP_API_BASE_URL + "clothes",
        stockAdjustment: process.env.VUE_APP_API_BASE_URL + "stock-adjustments",
      },
      setDataCloth: [],
      dataClothDetail: null,
      setDataClothDetail: [],
      selectedColor: null,
      setDataOrderDetail: [],
      filterClothId: null,
      setDataClothColor: [],
      rowCount: 6,
    };
  },
  watch: {
    setDataCloth: {
      handler(newVal) {
        this.setDataClothDetail = this.addCurrentStockInClothSize(newVal);
      },
      deep: true,
    },
    filteredData(newData) {
      this.setDataClothColor = newData[0];
    },
    setDataClothColor(value) {
      if (value) {
        this.selectedColor = value.cloth_colors[0];
      }
    },
  },
  computed: {
    total_current_stock() {
      let total = 0;
      this.setDataClothDetail.forEach((entry) => {
        entry.cloth_colors.forEach((color) => {
          color.cloth_sizes.forEach((item) => {
            total +=
              item.current_stock !== null ? parseInt(item.current_stock) : null;
          });
        });
      });
      return total;
    },
    total_stock() {
      let total = 0;
      this.setDataClothDetail.forEach((entry) => {
        entry.cloth_colors.forEach((color) => {
          color.cloth_sizes.forEach((item) => {
            if (item.current_stock !== null)
              total += item.stock > 0 ? parseInt(item.stock) : 0;
          });
        });
      });
      return total;
    },
    filteredData() {
      return this.setDataClothDetail.filter(
        (item) => item.id === this.filterClothId
      );
    },
    filteredDataFinal() {
      const filteredData = this.setDataClothDetail.filter((item) => {
        return item.cloth_colors.some((color) => {
          return color.cloth_sizes.some((size) => size.current_stock !== null);
        });
      });
      const filteredDataWithPositiveCurrentStock = filteredData.filter(
        (item) => {
          return item.cloth_colors.some((color) => {
            return color.cloth_sizes.some(
              (size) => size.current_stock !== null
            );
          });
        }
      );

      return filteredDataWithPositiveCurrentStock;
    },
  },
  mounted() {
    this.getCloth();
  },
  methods: {
    convertJSON(value) {
      const convertedObject = JSON.parse(JSON.stringify(value));
      return convertedObject;
    },
    formattedDate(value) {
      return convertDateTime(value);
    },
    formattedPrice(value) {
      return convertPrice(value);
    },
    async getCloth() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.cloth}?use_detail_cloth=1`)
          .then((response) => {
            this.setDataCloth = response.data.data;
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },
    addCurrentStockInClothSize(newVal) {
      return newVal.map((item) => {
        const newItem = { ...item };
        newItem.cloth_colors = item.cloth_colors.map((color) => {
          const newColor = { ...color };
          newColor.cloth_sizes = color.cloth_sizes.map((size) => {
            return {
              ...size,
              current_stock: null,
            };
          });
          return newColor;
        });
        return newItem;
      });
    },
    addFormDataSize(id) {
      this.$refs["tambah-item-modal-detail"].show();
      this.filterClothId = id;
    },
    totalCurrentStockIndex(id) {
      let total = 0;
      this.setDataClothDetail.forEach((data) => {
        const colorEntry = data.cloth_colors.find((item) => item.id === id);
        if (colorEntry) {
          colorEntry.cloth_sizes.forEach((item) => {
            total +=
              item.current_stock !== null ? parseInt(item.current_stock) : null;
          });
        }
      });
      return total;
    },
    totalRowspan(id) {
      let total = 0;
      this.setDataClothDetail.forEach((data) => {
        const colorEntry = data.cloth_colors.find((item) => item.id === id);
        if (colorEntry) {
          colorEntry.cloth_sizes.forEach((item, index) => {
            if (parseInt(item.current_stock) > 0) total++;
          });
        }
      });
      return total;
    },
    indexWithStockGreaterThanZeroForColor(colorId) {
      const colorEntry = this.setDataClothDetail.find((data) =>
        data.cloth_colors.find((color) => color.id === colorId)
      );
      if (colorEntry) {
        const clothColorEntry = colorEntry.cloth_colors.find(
          (color) => color.id === colorId
        );
        if (clothColorEntry) {
          return clothColorEntry.cloth_sizes.findIndex(
            (clothSize) => parseInt(clothSize.current_stock) > 0
          );
        }
      }
      return -1;
    },
    async onSubmit() {
      let stock_adjusment = this.setDataClothDetail
        .flatMap((item) => {
          return item.cloth_colors.flatMap((color) => {
            return color.cloth_sizes
              .filter((sizeItem) => parseInt(sizeItem.current_stock) !== 0)
              .map((sizeItem) => {
                if (sizeItem.current_stock) {
                  return {
                    cloth_size_id: sizeItem.id,
                    stock: parseInt(sizeItem.current_stock),
                  };
                }
              });
          });
        })
        .filter(Boolean);
      let saveFormOrder = {
        stock_adjusments: stock_adjusment,
      };
      if (saveFormOrder.stock_adjusments.length > 0) {
        try {
          this.isLoading = true;
          await axios
            .post(`${this.apiUrl.stockAdjustment}`, saveFormOrder, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then(() => {
              this.isLoading = false;
              this.getCloth();
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      }
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body v-if="!isLoading">
        <div class="mt-4">
          <div class="row justify-content-center">
            <div
              class="col-sm-4 col-md-3 col-lg-2 col-custom my-2 d-flex"
              v-for="(cloth, indexCloth) in setDataClothDetail"
              :key="indexCloth"
            >
              <div
                class="card card-custom ratio ratio-1x1 animate-card text-white w-100"
                @click="addFormDataSize(cloth.id)"
              >
                <template v-for="color in cloth.cloth_colors">
                  <template v-for="size in color.cloth_sizes">
                    <span
                      class="badge bg-info badge-custom position-absolute top-0 end-0 m-2"
                      v-if="
                        size.current_stock !== null &&
                          total_current_stock !== null
                      "
                      >{{ totalCurrentStockIndex(color.id) }}</span
                    >
                  </template>
                </template>
                <img
                  :src="
                    cloth.cloth_colors[0].image !== null
                      ? `${apiUrl.baseUrl}/storage/${cloth.cloth_colors[0].image}`
                      : imgDummy
                  "
                  class="card-img h-100"
                  style="object-fit: cover;"
                  alt="..."
                />
                <div
                  class="card-img-overlay"
                  style="cursor: pointer;"
                >
                  <div class="bottom-left fw-bold card-title-custom text-dark">
                    <p>
                      {{
                        cloth.cloth_category ? cloth.cloth_category.name : "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <template v-if="filteredDataFinal.length > 0">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead class="head-custom fw-bold">
                    <tr class="text-center font-size-15">
                      <td rowspan="2">Gambar</td>
                      <td rowspan="2">Produk</td>
                      <td rowspan="2">Warna</td>
                      <td rowspan="2">Ukuran</td>
                      <td colspan="3">Stok</td>
                    </tr>
                    <tr class="text-center">
                      <td>Lama</td>
                      <td>Penyesuaian</td>
                      <td>Saat Ini</td>
                    </tr>
                  </thead>
                  <tbody class="text-center align-middle">
                    <template v-for="item in filteredDataFinal">
                      <template
                        v-for="color in item.cloth_colors"
                        v-if="totalCurrentStockIndex(color.id) > 0"
                      >
                        <tr class="text-center align-middle">
                          <td :rowspan="totalRowspan(color.id)">
                            <img
                              :src="
                                color.image !== null
                                  ? `${apiUrl.baseUrl}storage/${color.image}`
                                  : imgDummy
                              "
                              alt="Image"
                              class="avatar-md img-responsive shadow border rounded"
                              style="object-fit: cover;"
                            />
                          </td>
                          <td :rowspan="totalRowspan(color.id)" class="fw-bold">
                            {{
                              item.cloth_category
                                ? item.cloth_category.name
                                : "-"
                            }}
                          </td>
                          <td :rowspan="totalRowspan(color.id)" class="fw-bold">
                            <div
                              class="d-flex justify-content-center align-items-center gap-2"
                            >
                              <span
                                class="indicator-color-two border border-1 border-secondary"
                                :style="{
                                  background: `#${color.color.code_hexa}`,
                                }"
                              ></span>
                            </div>
                          </td>
                          <template
                            v-if="
                              color.cloth_sizes.some(
                                (size) => parseInt(size.current_stock) > 0
                              )
                            "
                          >
                            <td>
                              {{
                                color.cloth_sizes[
                                  indexWithStockGreaterThanZeroForColor(
                                    color.id
                                  )
                                ].size.name
                              }}
                            </td>
                            <td>
                              {{
                                color.cloth_sizes[
                                  indexWithStockGreaterThanZeroForColor(
                                    color.id
                                  )
                                ].stock
                              }}
                            </td>
                            <td>
                              <span
                                :class="{
                                  'text-danger':
                                    parseInt(
                                      color.cloth_sizes[
                                        indexWithStockGreaterThanZeroForColor(
                                          color.id
                                        )
                                      ].current_stock
                                    ) -
                                      color.cloth_sizes[
                                        indexWithStockGreaterThanZeroForColor(
                                          color.id
                                        )
                                      ].stock <
                                    0,
                                  'text-success':
                                    parseInt(
                                      color.cloth_sizes[
                                        indexWithStockGreaterThanZeroForColor(
                                          color.id
                                        )
                                      ].current_stock
                                    ) -
                                      color.cloth_sizes[
                                        indexWithStockGreaterThanZeroForColor(
                                          color.id
                                        )
                                      ].stock >=
                                    0,
                                }"
                              >
                                {{
                                  parseInt(
                                    color.cloth_sizes[
                                      indexWithStockGreaterThanZeroForColor(
                                        color.id
                                      )
                                    ].current_stock
                                  ) -
                                    color.cloth_sizes[
                                      indexWithStockGreaterThanZeroForColor(
                                        color.id
                                      )
                                    ].stock <
                                  0
                                    ? ""
                                    : "+"
                                }}{{
                                  parseInt(
                                    color.cloth_sizes[
                                      indexWithStockGreaterThanZeroForColor(
                                        color.id
                                      )
                                    ].current_stock
                                  ) -
                                    color.cloth_sizes[
                                      indexWithStockGreaterThanZeroForColor(
                                        color.id
                                      )
                                    ].stock
                                }}
                              </span>
                            </td>
                            <td>
                              {{
                                color.cloth_sizes[
                                  indexWithStockGreaterThanZeroForColor(
                                    color.id
                                  )
                                ].current_stock
                              }}
                            </td>
                          </template>
                        </tr>
                        <tr
                          v-for="(size, indexSize) in color.cloth_sizes"
                          :key="size.id"
                          v-if="
                            parseInt(size.current_stock) > 0 &&
                              indexSize >
                                indexWithStockGreaterThanZeroForColor(color.id)
                          "
                        >
                          <td>{{ size.size.name }}</td>
                          <td>{{ size.stock }}</td>
                          <td>
                            <span
                              :class="{
                                'text-danger':
                                  size.current_stock - size.stock < 0,
                                'text-success':
                                  size.current_stock - size.stock >= 0,
                              }"
                            >
                              {{
                                parseInt(size.current_stock) - size.stock < 0
                                  ? ""
                                  : "+"
                              }}{{ parseInt(size.current_stock) - size.stock }}
                            </span>
                          </td>
                          <td>{{ size.current_stock }}</td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                  <tfoot class="head-custom">
                    <tr class="fw-bold">
                      <td class="text-end" colspan="4">
                        Total
                      </td>
                      <td class="text-center">{{ total_stock }}</td>
                      <td></td>
                      <td class="text-center">
                        {{ total_current_stock }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="text-center text-muted custom-adjusment mt-3">
                <span class="uil uil-exclamation-circle fs-custom"></span>
                <p class="mx-3">
                  Pilih produk terlebih dahulu dan harap berhati-hati dalam
                  pengisian penyesuaian stok
                </p>
              </div>
            </template>
            <div class="mt-4">
              <b-button
                variant="success"
                class="btn-block fw-bold"
                @click="onSubmit"
                :disabled="total_current_stock > 0 ? false : true"
                >Simpan</b-button
              >
            </div>
          </div>
        </div>
      </b-card-body>
      <b-card-body v-else>
        <div class="row">
          <div
            v-for="row in rowCount"
            :key="row"
            class="col-sm-4 col-md-3 col-lg-2 mt-3"
          >
            <skeleton width="100%" height="12rem" borderRadius="7px" />
          </div>
          <div class="mt-4">
            <skeleton width="100%" height="7rem" borderRadius="7px" />
          </div>
        </div>
      </b-card-body>
    </b-card>

    <!-- modal cloth detail -->
    <b-modal
      ref="tambah-item-modal-detail"
      size="lg"
      hide-footer
      centered
      :title="
        filteredData.length > 0
          ? filteredData[0].cloth_category
            ? filteredData[0].cloth_category.name
            : '-'
          : '-'
      "
      class="pl-0"
    >
      <div class="container-fluid" v-if="!isLoadingModalDetail">
        <template v-if="filteredData.length > 0">
          <multiselect
            v-model="selectedColor"
            :options="setDataClothColor.cloth_colors"
            :searchable="true"
            track-by="id"
            placeholder="Select a color"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.color !== null ? option.color.name : "-" }}
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.color !== null ? option.color.name : "-" }}
            </template>
          </multiselect>
          <div class="table-responsive mt-4">
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="text-center vertical-align-middle">
                  <th rowspan="2">Ukuran</th>
                  <th colspan="2">Stok</th>
                  <th rowspan="2">Penyesuaian</th>
                </tr>
                <tr class="text-center">
                  <th>Lama</th>
                  <th>Saat Ini</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="select in selectedColor.cloth_sizes"
                  :key="select.id"
                  class="text-center"
                >
                  <td>{{ select.size.name }}</td>
                  <td>{{ select.stock }}</td>
                  <td style="width: 35%;">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <b-button
                        variant="primary"
                        class="rounded-start rounded-0"
                        @click="select.current_stock--"
                        >-</b-button
                      >
                      <input
                        type="number"
                        class="form-control rounded-0"
                        v-model="select.current_stock"
                        :id="select.id + 'form-current-stock'"
                        placeholder="Masukkan Stok Terbaru"
                      />
                      <b-button
                        variant="primary"
                        class="rounded-end rounded-0"
                        @click="select.current_stock++"
                        >+</b-button
                      >
                    </div>
                  </td>
                  <td>
                    <template v-if="select.current_stock">
                      <span
                        :class="
                          parseInt(select.current_stock) - select.stock < 0
                            ? 'text-danger text-center'
                            : 'text-success text-center'
                        "
                      >
                        {{
                          parseInt(select.current_stock) - select.stock < 0
                            ? ""
                            : "+"
                        }}{{ parseInt(select.current_stock) - select.stock }}
                      </span>
                    </template>
                    <template v-else
                      ><span class="text-center">0</span></template
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <div v-else>loading...</div>
    </b-modal>
  </Layout>
</template>
<style>
.vertical-align-middle {
  vertical-align: middle;
}
.fs-custom {
  font-size: 5rem;
}
.badge-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 12px;
  padding: 6px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
.h-custom {
  height: 15rem;
}

.table-bordered {
  border: 1px solid #d4d4d4 !important;
}
.head-custom {
  background-color: var(--bs-table-striped-bg);
}
.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  /* margin: 10px; */
  margin: 10px 12px 10px 20px;
}

.animate-card-img-overlay {
  transition: 0.4s;
}

.animate-card-img-overlay:hover {
  background-color: #0000006c;
  overflow: auto;
  cursor: pointer;
}

.animate-card:hover .card-title-custom {
  display: block !important;
}

.animate-card-img {
  transition: transform 0.3s;
}

.animate-card:hover .animate-card-img {
  transform: translate(0, -10%) scale(1.1);
  cursor: pointer;
}

@media (max-width: 575px) {
  .bg-dark {
    background-color: transparent !important;
    color: #495057 !important;
  }
  .card-custom {
    flex-direction: row;
    height: 6rem;
    margin-bottom: 0;
  }

  .badge-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px !important;
    right: 200px !important;
    font-size: 12px;
    padding: 6px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  .animate-card-img-overlay:hover {
    background-color: #00000000 !important;
    overflow: auto;
    cursor: pointer;
  }

  .card-title-custom {
    display: block !important;
    color: #000;
  }

  .card-custom img {
    /* margin: 0; */
    width: 6rem;
    /* height: auto !important; */
    margin-right: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bottom-left {
    position: unset;
    margin: 0;
  }

  .bottom-left > p {
    margin-bottom: 0;
  }
  .card-body-custom {
    align-items: center !important;
    flex-direction: unset !important;
    padding-left: 7.5rem;
  }
  .card-title-custom {
    /* position: absolute;
    bottom: 0;
    left: 75px; */
  }
}
@media (min-width: 576px) and (max-width: 660px) {
  .col-custom {
    flex: 0 0 40%;
    max-width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .col-custom {
    flex: 0 0 30%;
    max-width: 100%;
  }
}
@media (min-width: 1101px) and (max-width: 1400px) {
  .col-custom {
    flex: 0 0 25%;
    max-width: 100%;
  }
}

.custom-adjusment {
  border: 3px dashed;
  border-radius: 8px;
}
</style>
